.filter-option {
    transition: all 250ms ease-in-out;
    padding: 10px;
}

.filter-option:not(:last-of-type) {
    border-bottom: 2px solid lightgrey;
}

.filter-option:hover {
    background-color: #eee;
}

.invisible {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.win {
    opacity: 1;
    color:forestgreen;
}

.loss {
    opacity: 1;
    color: rgb(173, 35, 35);
}

.win-image {
    filter: blur(0px) !important;
}

.loss-image {
    filter: blur(0px) !important;
}