.shadow {
    position: fixed;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    transition: top 0ms 300ms, opacity 300ms 0ms ease-in-out;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 80%);
    transition: transform 500ms ease-in-out;
}

.shadow-active {
    top: 0; 
    opacity: 1;
    pointer-events: all;
    transition: top 0ms 0ms, opacity 300ms 0ms ease-in-out;
}

.popup-active {
    transform: translate(-50%, -50%);
}