.card {
    position: relative;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.card h3 {
    margin-block-start: 0;
}

.card-scroll {
    padding: 5px;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
}


